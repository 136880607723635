import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const IndexPage = () => {

  return (
    <>
    <Header/>
    <Layout>
      <SEO title="Home" />
      <div className="row">
        <div className="columns small-12 medium-6">
        <p>Instead of sharing their lives on public social profiles, Gen Z relies on DMs and group chats to stay connected — making it more difficult for brands to understand Gen Z and how to reach them. We created Reveal based on a need to uncover meaningful, qualitative insights about this generation.</p>
        <p>Through this vibrant community, we’ve surfaced <em>how Gen Z saves their money, fights climate change, and even decides what to stream on the weekends</em>. Now, we’re focusing on how they’re navigating the COVID crisis.</p>
        <p className="bc">Do you have a question you’d like to put to the Reveal Gen Z panel?</p>
        <p className="bc">Want to know more about the panel and how we can tailor insights to your unique situation?</p>
        <p><strong>Complete this form to receive free PDFs of Big Spaceship Reveal<sup>&trade;</sup>: The Rules of Gen Z and Big Spaceship Reveal<sup>&trade;</sup>: Gen Z & Spending. They include information on how brands can drive relevance and meaningfully engage with this new — and often untapped — audience.</strong></p>
        <hr/>
        <p><strong>Big Spaceship<sup>&reg;</sup></strong> is a modern partner to ambitious brands. We analyze culture and behavior to find transformational insights for our clients.</p>
        </div>
        <div className="columns small-12 medium-6">
          <form
            className="main-form"
            name="reveal-twenty-twenty"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="reveal-twenty-twenty" />
            <div className="fr">
              <h4>sign up</h4>
            </div>
            <div className="fr">
              <label htmlFor="fn">First Name*</label>
              <input id="fn" name="first-name" type="text" placeholder="Your First Name" required />
              <span className="errorMessage">*This is an error message.</span>
            </div>
            <div className="fr">
              <label htmlFor="ln">Last Name*</label>
              <input id="ln" name="last-name" type="text" placeholder="Your Last Name" required />
              <span className="errorMessage">*This is an error message.</span>
            </div>
            <div className="fr">
              <label htmlFor="ce">Business Email*</label>
              <input id="ce" name="business-email" type="email" placeholder="Your Business Email Address" required />
              <span className="errorMessage">*This is an error message.</span>
            </div>
            <div className="fr">
              <label htmlFor="cn">Current/Former Organization (optional)</label>
              <input id="cn" name="company-name" type="text" placeholder="Your Current/Former Organization Name" />
              <span className="errorMessage">*This is an error message.</span>
            </div>
            <div className="fr">
              <label htmlFor="ttl">Current/Former Title (optional)</label>
              <input id="ttl" name="title" type="text" placeholder="Your Current/Former Title" />
              <span className="errorMessage">*This is an error message.</span>
            </div>

            <div className="fr">
              <label htmlFor="know-more">What do you want to know from Gen Z? (optional)</label>
              <input id="know-more" name="tell-us-more" type="text" placeholder="Tell us more..." />
              <span className="errorMessage">*This is an error message.</span>
            </div>
            <div className="fr">
              <fieldset>
                <legend>Want to schedule more time to learn more about Reveal?</legend>
                <label className="cb-label"><input type="radio" id="yes-learn-more" name="learn-more" value="yes-learn-more" defaultChecked /><span className="checker"></span> Yes, I'd like to schedule more time to discuss Reveal.</label>
                <label className="cb-label"><input type="radio" id="no-learn-more" name="learn-more" value="no-learn-more" /><span className="checker"></span> No, thank you.</label>
              </fieldset>
            </div>
            <div className="fr row align-middle">
              <div className="columns text-right small-12 medium-6">
                <div className="submitNote">Please complete the required form fields.</div>
              </div>
              <div className="columns text-right small-12 medium-6">
                <button className="sub-btn" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
    </>
  )
};

export default IndexPage
